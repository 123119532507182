import { useEffect, useState } from 'react';
import { SwipeQuestionCard } from '../../../../interfaces/swipe-question-card.interface';
import { SwipeQuestionFinishDataWithRank } from 'src/interfaces/swipe-question-finish-data-with-rank.interface';

import styles from './LpSwipeQuestionResultForQuiz.module.css';
import { ChartView } from '../../../../enums/chart-view.enum';
import { SortOrder } from '../../../../enums/sort-order.enum';
import { SwipeQuestionCardSide } from '../../../../interfaces/swipe-question-card-side.interface';
import { SwipeQuestionOption } from '../../../../interfaces/swipe-question-option.interface';
import { CardWithTitleForQuiz } from '../../components/CardWithTitleForQuiz';
import { LpBar } from './LpBar';
import { SwipeQuestionResponse } from 'src/interfaces/swipe-question-response.interface';

interface Props {
  responsesWithRank: SwipeQuestionFinishDataWithRank[];
  question: { swipeOptions: SwipeQuestionOption; cards: SwipeQuestionCard[] };
  view: ChartView;
  order: SortOrder;
  isReportScreen?: boolean;
}

export const LpSwipeQuestionResultForQuiz = ({
  responsesWithRank,
  question,
  view,
  order,
  isReportScreen,
}: Props) => {
  const [updatedResponses, setUpdatedResponses] = useState<
    SwipeQuestionFinishDataWithRank[]
  >([]);
  const cards = question.cards;

  useEffect(() => {
    const prepareCardView = (responses: SwipeQuestionFinishDataWithRank[]) => {
      return cards.map(card => responses.find(res => res.cardId === card.id)!);
    };
    if (view === ChartView.CHRONOLOGICAL) {
      const result = prepareCardView(responsesWithRank);
      if (order === SortOrder.ASC) {
        setUpdatedResponses([...result].reverse());
      } else {
        setUpdatedResponses(result);
      }
    } else {
      if (order === SortOrder.ASC) {
        setUpdatedResponses([...responsesWithRank].reverse());
      } else {
        setUpdatedResponses(responsesWithRank);
      }
    }
  }, [order, view, cards, responsesWithRank]);

  return (
    <div
      className={`${styles.innerContainer} ${
        isReportScreen ? styles.innerContainerForReport : ''
      }`}
    >
      {updatedResponses.map(resp => {
        return (
          <div className={styles.row} key={resp.cardId}>
            <div className={styles.leftSide}>
              <CardWithTitleForQuiz
                swipeQuestionCard={cards.find(card => card.id === resp.cardId)!}
                swipeQuestionOption={question.swipeOptions}
                rank={resp.rank}
              />
            </div>

            <div className={styles.rightSide}>
              <LpBar
                left={getCorrectPercentage(resp, cards)}
                right={getInCorrectPercentage(resp, cards)}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

const getPercentageForCorrectOption = (
  response: SwipeQuestionResponse,
  cardId: string,
  cards: SwipeQuestionCard[],
) => {
  const card = cards.find(card => card.id === cardId);
  const total = Number(response.leftCount) + Number(response.rightCount);

  if (
    card?.correctSide === SwipeQuestionCardSide.LEFT &&
    response.leftCount > 0
  ) {
    return (response.leftCount * 100) / total;
  }

  if (
    card?.correctSide === SwipeQuestionCardSide.RIGHT &&
    response.rightCount > 0
  ) {
    return (response.rightCount * 100) / total;
  }

  return 0;
};

const getCorrectPercentage = (
  resp: SwipeQuestionResponse,
  cards: SwipeQuestionCard[],
) => {
  const percentage = getPercentageForCorrectOption(resp, resp.cardId, cards);
  return Math.round(percentage);
};

const getInCorrectPercentage = (
  resp: SwipeQuestionResponse,
  cards: SwipeQuestionCard[],
) => {
  const percentage = getPercentageForCorrectOption(resp, resp.cardId, cards);
  const card = cards.find(card => card.id === resp.cardId);

  if (
    card?.correctSide !== SwipeQuestionCardSide.RIGHT &&
    resp.rightCount === 0
  ) {
    return 0;
  }

  if (
    card?.correctSide !== SwipeQuestionCardSide.LEFT &&
    resp.leftCount === 0
  ) {
    return 0;
  }

  return Math.round(100 - percentage);
};
