import { WuTooltip } from '@npm-questionpro/wick-ui-lib';
import { SortOrder } from '../../../../enums/sort-order.enum';
import ascendingSvg from '../../../../images/ascending.svg';
import descendingSvg from '../../../../images/descending.svg';
import styles from './LpSwipeQuestionSortIcon.module.css';

interface Props {
  onChangeOrder: (sortOrder: SortOrder) => void;
  order: SortOrder;
}

export const LpSwipeQuestionSortIcon = ({ order, onChangeOrder }: Props) => {
  if (order === SortOrder.DESC) {
    return (
      <div className={styles.descImage}>
        <WuTooltip content="Reverse order" position="bottom">
          <img
            src={descendingSvg}
            onClick={() => onChangeOrder(SortOrder.ASC)}
            alt="desc order"
            className={styles.icon}
          />
        </WuTooltip>
      </div>
    );
  }

  return (
    <div className={styles.ascImage}>
      <WuTooltip content="Reverse order" position="bottom">
        <img
          src={ascendingSvg}
          onClick={() => onChangeOrder(SortOrder.DESC)}
          alt="asc order"
          className={styles.icon}
          height={16}
        />
      </WuTooltip>
    </div>
  );
};
