import { ChartView } from 'src/enums/chart-view.enum';
import styles from './LpSwipeQuestionHeader.module.css';
import { LpSwipeQuestionToggleForQuiz } from './LpSwipeQuestionToggleForQuiz';
import { LpSwipeQuestionSortIcon } from './LpSwipeQuestionSortIcon';
import { SortOrder } from '../../../../enums/sort-order.enum';
import { SwipeQuestionChartView } from '../../../../enums/teams-chart-view.enum';
import { LpSwipeQuestionToggleForTeams } from './LpSwipeQuestionToggleForTeams';

interface Props {
  currentView: ChartView;
  order: SortOrder;
  teamsOrIndividualView: SwipeQuestionChartView;
  isTeamsEnabled: boolean;
  onChangeView: (view: ChartView) => void;
  onChangeOrder: (order: SortOrder) => void;
  onChangeTeamsOrIndividualView: (view: SwipeQuestionChartView) => void;
}

export const LpSwipeQuestionHeader = ({
  currentView,
  order,
  teamsOrIndividualView,
  isTeamsEnabled = false,
  onChangeOrder,
  onChangeView,
  onChangeTeamsOrIndividualView,
}: Props) => {
  const showAccuracyForTeams =
    isTeamsEnabled && teamsOrIndividualView === SwipeQuestionChartView.TEAMS;

  return (
    <div className={styles.container}>
      <div className={styles.leftSide}>
        <LpSwipeQuestionToggleForQuiz
          currentView={currentView}
          onChangeView={onChangeView}
        />
        {isTeamsEnabled && (
          <LpSwipeQuestionToggleForTeams
            currentView={teamsOrIndividualView}
            onChangeView={onChangeTeamsOrIndividualView}
          />
        )}
        <LpSwipeQuestionSortIcon order={order} onChangeOrder={onChangeOrder} />
      </div>

      {!showAccuracyForTeams && (
        <div className={styles.rightSide}>
          <div>Correct</div>
          <div>Incorrect</div>
        </div>
      )}
      {showAccuracyForTeams && (
        <div className={styles.rightSideForTeams}>
          <div>Accuracy</div>
        </div>
      )}
    </div>
  );
};
