export const CopyLinkSvg = () => {
  const image = (
    <svg
      width="17"
      height="10"
      viewBox="0 0 17 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0422 0H14.7603C15.2061 0 15.3677 0.0464128 15.5307 0.133566C15.6936 0.22072 15.8215 0.348614 15.9087 0.511577C15.9958 0.67454 16.0422 0.836183 16.0422 1.28194V8.71806C16.0422 9.16382 15.9958 9.32546 15.9087 9.48842C15.8215 9.65139 15.6936 9.77928 15.5307 9.86643C15.3677 9.95359 15.2061 10 14.7603 10H10.0422V8H14.0422V2H10.0422V0ZM1.32418 0H6.04224V2H2.04224V8H6.04224V10H1.32418C0.87842 10 0.716776 9.95359 0.553813 9.86643C0.39085 9.77928 0.262956 9.65139 0.175803 9.48842C0.0886491 9.32546 0.0422363 9.16382 0.0422363 8.71806V1.28194C0.0422363 0.836183 0.0886491 0.67454 0.175803 0.511577C0.262956 0.348614 0.39085 0.22072 0.553813 0.133566C0.716776 0.0464128 0.87842 0 1.32418 0ZM7.04224 4H9.04224C9.59452 4 10.0422 4.44772 10.0422 5C10.0422 5.55228 9.59452 6 9.04224 6H7.04224C6.48995 6 6.04224 5.55228 6.04224 5C6.04224 4.44772 6.48995 4 7.04224 4Z"
        fill="currentColor"
      />
    </svg>
  );
  return image;
};
