import * as React from 'react';
import styles from './LpProductDropdown.module.css';
import qpLogo from '../../images/qp-logo.svg';

export const LpProductDropdown = () => {
  const dropDownBtnRef = React.useRef<HTMLButtonElement | null>(null);

  return (
    <div className={styles.qpProductDropdown}>
      <button
        type="button"
        className={styles.dropdownToggle}
        ref={dropDownBtnRef}
      >
        <span className={styles.text}>
          <img
            src={qpLogo}
            className={styles.qpIcon}
            alt="LivePolls"
            height="20px"
            width="20px"
          />
          LivePolls
        </span>
      </button>
    </div>
  );
};
