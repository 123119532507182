import styles from './Header.module.css';
import { LpProductDropdown } from '@livepolls/ui-components/src/components/product-dropdown/LpProductDropdown';

export const Header = () => {
  return (
    <div className={styles.header}>
      <div className={styles.topHeader}>
        <LpProductDropdown />
      </div>
    </div>
  );
};
