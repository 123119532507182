import styles from './LpQuestionResultForTeams.module.css';
import { SwipeQuestionCard } from 'src/interfaces/swipe-question-card.interface';
import { SwipeQuestionOption } from '../../../../interfaces/swipe-question-option.interface';
import { SwipeQuestionFinishDataWithRank } from 'src/interfaces/swipe-question-finish-data-with-rank.interface';
import { ChartView } from '../../../../enums/chart-view.enum';
import { SortOrder } from '../../../../enums/sort-order.enum';
import { CardWithTitleForQuiz } from '../../components/CardWithTitleForQuiz';
import { SwipeQuestionTeamResponse } from 'src/interfaces/swipe-question-team-response.interface';
import { ITeam } from 'src/interfaces/team.interface';
import { TeamChart } from '../../components/TeamChart';
import { useEffect, useState } from 'react';

interface Props {
  responsesWithRank: SwipeQuestionFinishDataWithRank[];
  teamResponses: SwipeQuestionTeamResponse[];
  question: { swipeOptions: SwipeQuestionOption; cards: SwipeQuestionCard[] };
  view: ChartView;
  order: SortOrder;
  teams: ITeam[];
  isReportScreen?: boolean;
}

export const LpSwipeQuestionResultForTeams = ({
  responsesWithRank,
  teamResponses,
  teams,
  question,
  view,
  order,
  isReportScreen,
}: Props) => {
  const [updatedResponses, setUpdatedResponses] = useState<
    SwipeQuestionFinishDataWithRank[]
  >([]);

  useEffect(() => {
    const cards = question.cards;

    const prepareCardView = (responses: SwipeQuestionFinishDataWithRank[]) => {
      return cards.map(card => responses.find(res => res.cardId === card.id)!);
    };
    if (view === ChartView.CHRONOLOGICAL) {
      const result = prepareCardView(responsesWithRank);
      if (order === SortOrder.ASC) {
        setUpdatedResponses([...result].reverse());
      } else {
        setUpdatedResponses(result);
      }
    } else {
      if (order === SortOrder.ASC) {
        setUpdatedResponses([...responsesWithRank].reverse());
      } else {
        setUpdatedResponses(responsesWithRank);
      }
    }
  }, [question.cards, order, view, responsesWithRank]);

  const getTeamsChartContent = (cardId: string) => {
    const teamResponsesForCard = teamResponses.filter(
      response => response.cardId === cardId,
    );

    // add teams without responses to the list
    teams.forEach(team => {
      if (!teamResponsesForCard.some(resp => resp.teamUid === team.id)) {
        teamResponsesForCard.push({
          questionId: '',
          cardId,
          teamUid: team.id,
          correctCount: 0,
          responseCount: 0,
        });
      }
    });

    // sort by percentage in descending order
    const teamsPiceChartData = teamResponsesForCard
      .map(resp => {
        const team = teams.find(team => team.id === resp.teamUid)!;

        return {
          cardId: resp.cardId,
          teamUid: resp.teamUid,
          name: team.name,
          color: team.color,
          percentage: getPercentageForCorrectOption(
            resp.correctCount,
            resp.responseCount,
          ),
        };
      })
      .sort((a, b) => b.percentage - a.percentage);

    return <TeamChart teamResponses={teamsPiceChartData} />;
  };

  return (
    <div
      className={`${styles.innerContainer} ${
        isReportScreen ? styles.innerContainerForReport : ''
      }`}
    >
      {updatedResponses.map(resp => {
        return (
          <div className={styles.row} key={resp.cardId}>
            <div className={styles.leftSide}>
              <CardWithTitleForQuiz
                swipeQuestionCard={
                  question.cards.find(card => card.id === resp.cardId)!
                }
                swipeQuestionOption={question.swipeOptions}
                rank={resp.rank}
              />
            </div>

            <div className={styles.rightSide}>
              {getTeamsChartContent(resp.cardId)}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const getPercentageForCorrectOption = (
  correctCount: number,
  totalResponses: number,
) => {
  if (totalResponses === 0) {
    return 0;
  }

  return Math.round((correctCount / totalResponses) * 100);
};
