import { useEffect, useState } from 'react';
import { SwipeQuestionCard } from 'src/interfaces/swipe-question-card.interface';
import { SwipeQuestionOption } from 'src/interfaces/swipe-question-option.interface';
import { SwipeQuestionResult } from 'src/interfaces/swipe-question-result.interface';

import { LpSpinner } from '../spinner/LpSpinner';
import { LpSwipeQuestionChartForQuiz } from '../swipe-question-charts/quiz/LpSwipeQuestionChartForQuiz';
import { LpSwipeQuestionChartForPoll } from '../swipe-question-charts/poll/LpSwipeQuestionChartForPoll';
import { ITeam } from 'src/interfaces/team.interface';
import { SwipeQuestionResponse } from 'src/interfaces/swipe-question-response.interface';
import { SwipeQuestionTeamResponse } from 'src/interfaces/swipe-question-team-response.interface';

interface Props {
  isQuiz: boolean;
  swipeQuestion: {
    swipeOptions: SwipeQuestionOption;
    cards: SwipeQuestionCard[];
  };
  text: {
    noDataAvailable: string;
    initializing: string;
  };
  questionResult: SwipeQuestionResult | undefined;
  isTeamsEnabled: boolean;
  teams?: ITeam[];
  customCardRowClass?: string;
  customHeaderRowClass?: string;
  customContainerClass?: string;
  isReportScreen?: boolean;
}

export const LpSwipeQuestionFinishedScreen = ({
  isQuiz,
  swipeQuestion,
  questionResult,
  text,
  customCardRowClass,
  customHeaderRowClass,
  customContainerClass,
  isTeamsEnabled,
  teams,
  isReportScreen,
}: Props) => {
  const [responses, setResponses] = useState<SwipeQuestionResponse[]>([]);
  const [teamResponses, setTeamResponses] = useState<
    SwipeQuestionTeamResponse[]
  >([]);

  useEffect(() => {
    if (!questionResult) {
      return;
    }

    // filter responses by cardId
    const filteredSwipeQuestionResponses =
      questionResult.swipeQuestionResponses.filter(swipeQuestionResponse =>
        swipeQuestion.cards.some(
          card => card.id === swipeQuestionResponse.cardId,
        ),
      );

    setResponses(filteredSwipeQuestionResponses);
    // filter team responses by cardId
    const filteredSwipeQuestionTeamResponses =
      questionResult.swipeQuestionResponsesByTeam?.filter(
        swipeQuestionTeamResponse =>
          swipeQuestion.cards.some(
            card => card.id === swipeQuestionTeamResponse.cardId,
          ),
      );
    setTeamResponses(filteredSwipeQuestionTeamResponses || []);
  }, [questionResult, swipeQuestion.cards]);

  if (!questionResult) {
    return <LpSpinner />;
  }

  if (isQuiz) {
    return (
      <LpSwipeQuestionChartForQuiz
        responses={responses}
        teamResponses={teamResponses}
        question={swipeQuestion}
        isTeamsEnabled={isTeamsEnabled}
        teams={teams}
        isReportScreen={isReportScreen}
      />
    );
  }

  return (
    <LpSwipeQuestionChartForPoll
      swipeQuestion={swipeQuestion}
      responses={questionResult.swipeQuestionResponses}
      customCardRowClass={customCardRowClass}
      customHeaderRowClass={customHeaderRowClass}
      customContainerClass={customContainerClass}
      isReportScreen={isReportScreen}
    />
  );
};
