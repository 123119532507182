import styles from './TeamChart.module.scss';
import ribbonSvg from '../../../images/blue-ribbon.svg';
import { LpPieChart } from '../quiz/teams/LpPieChart';
import { appendPositionPrefix } from '../../../utils/string.util';

interface Props {
  teamResponses: {
    cardId: string;
    teamUid: string;
    name: string;
    color: string;
    percentage: number;
  }[];
}

export const TeamChart = ({ teamResponses }: Props) => {
  const topTeam = teamResponses[0];
  const otherTeams = teamResponses.slice(1);

  return (
    <div className={styles.container}>
      <div className={styles.leftSide}>
        <span className={styles.topTeamName}>
          <img src={ribbonSvg} alt="ribbon" className={styles.ribbonImg} />
          {appendPositionPrefix(topTeam.name, 1)}
        </span>
        <div className={styles.topTeamPieChartContainer}>
          <LpPieChart
            width={48}
            height={48}
            color={topTeam.color}
            filledPercentage={topTeam.percentage}
          />
          <span className={styles.topTeamPercentageText}>
            {topTeam.percentage} <span>%</span>
          </span>
        </div>
      </div>
      <div className={styles.rightSide}>
        {otherTeams.map((team, idx) => (
          <div
            key={`${team.cardId}-${team.teamUid}`}
            className={`${styles.teamNameWithPieChartRow} ${
              idx !== 0 ? styles.borderTop : ''
            }`}
          >
            <span className={styles.teamName}>
              {appendPositionPrefix(team.name, idx + 2)}
            </span>
            <div className={styles.pieChartContainer}>
              <LpPieChart
                width={24}
                height={24}
                color={team.color}
                filledPercentage={team.percentage}
              />
              <span className={styles.percentageText}>{team.percentage}%</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
