import styles from './LpSwipeQuestionLeaderboard.module.css';
import { RibbonSvg } from '../../svgImages/Ribbon.svg';
import { ISwipeQuestionReport } from 'src/interfaces/swipe-question-report.interface';
import { WuTooltip } from '@npm-questionpro/wick-ui-lib';

interface Props {
  questionReport: ISwipeQuestionReport;
}

export const LpSwipeQuestionLeaderboard = ({ questionReport }: Props) => {
  const { leaderboard } = questionReport;

  if (leaderboard && leaderboard.length > 0) {
    const leaderboardRows = leaderboard.map(
      (r: { score: number; respondentName: string }, i) => (
        <div
          key={i}
          className={i === 0 ? styles.questionLeaderboardFirst : ''}
          data-testid="topRespondentsForSwipeQuestion"
        >
          {i === 0 && (
            <span className={styles.ribbonImage}>
              <RibbonSvg />
            </span>
          )}
          <div>
            <div className={styles.questionLeaderboardName}>
              <WuTooltip content={getHoverText(i)} position="bottom">
                {r.respondentName}
              </WuTooltip>
            </div>
            <div className={styles.questionLeaderboardScore}>{r.score}</div>
          </div>
        </div>
      ),
    );
    return <div className={styles.questionLeaderboard}>{leaderboardRows}</div>;
  }
  return <div className={styles.questionLeaderboard}></div>;
};

function getHoverText(index: number) {
  const rank = index + 1;
  return `Rank ${rank}`;
}
