import styles from './LpBar.module.css';

interface Props {
  left: number;
  right: number;
}
export const LpBar = ({ left, right }: Props) => {
  const onlyLeftPresent = left === 100;
  const onlyRightPresent = right === 100;

  return (
    <div className={styles.container}>
      <div className={styles.leftAndRightLabels}>
        <div className={styles.leftSideLabel}>{left}%</div>
        <div>{right}%</div>
      </div>
      <div className={styles.bar}>
        <div
          style={{ width: `${left}%` }}
          className={`${styles.leftSideColor} ${
            onlyLeftPresent ? styles.borderRadius : styles.leftSideBorderRadius
          }`}
        ></div>
        <div
          style={{ width: `${right}%` }}
          className={`${styles.rightSideColor} ${
            onlyRightPresent
              ? styles.borderRadius
              : styles.rightSideBorderRadius
          }`}
        ></div>
      </div>
    </div>
  );
};
