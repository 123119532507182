export const appendPositionPrefix = (name: string, position: number) => {
  if (position === 1) {
    return `1st. ${name}`;
  } else if (position === 2) {
    return `2nd. ${name}`;
  } else if (position === 3) {
    return `3rd. ${name}`;
  } else if (position === 4) {
    return `4th. ${name}`;
  }
};
