import { SwipeQuestionOption } from '../../../interfaces/swipe-question-option.interface';
import { SwipeQuestionCard } from '../../../interfaces/swipe-question-card.interface';
import styles from './CardWithTitleForQuiz.module.scss';
import { SwipeQuestionCardSide } from '../../../interfaces/swipe-question-card-side.interface';
import swipeLeftSvg from '../../../images/swipe-left.svg';
import swipeRightSvg from '../../../images/swipe-right.svg';

interface Props {
  swipeQuestionCard: SwipeQuestionCard;
  swipeQuestionOption: SwipeQuestionOption;
  rank: number;
}

export const CardWithTitleForQuiz = ({
  swipeQuestionCard,
  swipeQuestionOption,
  rank,
}: Props) => {
  const { title, correctSide, image } = swipeQuestionCard;
  const { left, right } = swipeQuestionOption;
  const leftSwipeIconWithText = (
    <div className={styles.leftSwipeIconWithTextContainer}>
      <span>
        <img src={swipeLeftSvg} alt="swipe left icon" />
      </span>
      <span>{left.text}</span>
    </div>
  );

  const rightSwipeIconWithText = (
    <div className={styles.rightSwipeIconWithTextContainer}>
      <span>{right.text}</span>
      <span>
        <img src={swipeRightSvg} alt="swipe right icon" />
      </span>
    </div>
  );

  const correctAnswerContent =
    correctSide === SwipeQuestionCardSide.LEFT
      ? leftSwipeIconWithText
      : rightSwipeIconWithText;

  const cardContainerContent =
    image?.url != null && image.url !== '' ? (
      <div
        className={`${styles.imageCardContainer} ${
          correctSide === SwipeQuestionCardSide.LEFT
            ? styles.redBorder
            : styles.greenBorder
        }`}
      >
        <div className={styles.textContainer}>
          <div className={styles.textBg}></div>
          <span className={styles.imageCardRank}>#{rank}</span>
        </div>
        <img src={image?.url} alt="" className={`${styles.image}`} />
      </div>
    ) : (
      <div
        className={`${styles.textCardContainer} ${
          correctSide === SwipeQuestionCardSide.LEFT
            ? styles.redBorder
            : styles.greenBorder
        }`}
      >
        <span className={styles.textCardRank} data-testid="card rank">
          #{rank}
        </span>
      </div>
    );

  return (
    <div className={styles.mainContainer}>
      {cardContainerContent}
      <div className={styles.rightSideContainer}>
        <div className={styles.cardTitleContainer} data-testid="card title">
          <span>{title}</span>
        </div>
        <div className={styles.cardAnswerContainer}>
          <span>Answer:</span>
          {correctAnswerContent}
        </div>
      </div>
    </div>
  );
};
