import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import wordCloud from 'highcharts/modules/wordcloud';
import { WordCloudQuestionResponse } from '../../interfaces/word-cloud-question-response.interface';

interface Props {
  wordCloudQuestionResponses: WordCloudQuestionResponse[];
  text: {
    mentions: string;
  };
}
const MAX_WIDTH = 800;
const MAX_HEIGHT = 600;

const adjustWidthAndHeight = (innerWidth: number) => {
  const halfWIDTH = innerWidth / 2;
  if (halfWIDTH >= MAX_WIDTH) return { width: MAX_WIDTH, height: MAX_HEIGHT };
  else {
    return { width: halfWIDTH, height: halfWIDTH * 0.75 };
  }
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export const LpWordCloudImage = React.memo(
  ({ wordCloudQuestionResponses, text }: Props) => {
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions(),
    );
    const [width, setWidth] = useState<number>(
      adjustWidthAndHeight(windowDimensions.width).width,
    );

    const [height, setHeight] = useState<number>(
      adjustWidthAndHeight(windowDimensions.width).height,
    );

    useEffect(() => {
      const handleResize = () => {
        setWindowDimensions(getWindowDimensions());
      };

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
      setWidth(adjustWidthAndHeight(windowDimensions.width).width);
      setHeight(adjustWidthAndHeight(windowDimensions.width).height);
    }, [windowDimensions]);

    const ref = React.useRef<HTMLDivElement | null>(null);

    const color =
      document.documentElement.style.getPropertyValue('--buttonBackground') ||
      '#1b87e6';

    React.useEffect(() => {
      if (!ref.current) {
        return;
      }

      wordCloud(Highcharts);

      Highcharts.chart(ref.current, {
        series: [
          {
            type: 'wordcloud',
            data: wordCloudQuestionResponses,
            colors: [color],
            name: text.mentions,
            style: {
              fontFamily: "'Fira Sans', sans-serif",
            },
          },
        ],
        title: {
          text: '',
        },
        credits: {
          enabled: false,
        },
        chart: {
          width: width,
          height: height,
          backgroundColor: '#fff',
        },
        tooltip: {
          hideDelay: 0,
        },
      });
    }, [color, height, width, wordCloudQuestionResponses, text]);

    return (
      <div
        ref={ref}
        data-testid="wordCloudQuestionResultChart"
        style={{ width: `${width}px`, height: `${height}px` }}
      />
    );
  },
);
