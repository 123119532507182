import * as React from 'react';
import styles from './LpSpinner.module.css';
import { WuLoader } from '@npm-questionpro/wick-ui-lib';

interface Props {
  color?: string;
  message?: string;
  count?: number;
  customSpinnerClass?: string;
  customMainSpinnerClass?: string;
  size?: 'sm' | 'md' | 'lg';
}
const spinnerColor = '#1986e6';

export class LpSpinner extends React.Component<Props> {
  public render() {
    const {
      color,
      message,
      count,
      customSpinnerClass,
      customMainSpinnerClass,
      size = 'md',
    } = this.props;
    return (
      <div data-testid="spinner" className={`${customMainSpinnerClass || ''}`}>
        <div
          className={`${styles.spinner} ${customSpinnerClass || ''}`}
          style={color ? { backgroundColor: color } : {}}
        >
          <WuLoader color={spinnerColor} size={size} />
        </div>
        <div
          className={styles.loader}
          style={color ? { borderTopColor: color } : {}}
        />
        <div className={styles.loaderMessage}>
          {message} {count && count > 0 ? [<span>{count}</span>] : ''}
        </div>
      </div>
    );
  }
}
