import { useEffect, useState } from 'react';
import styles from './CopyReportUrl.module.css';
import { CopyLinkSvg } from '../svgImages/CopyLink.svg';
import { SHOW_TOAST_TIMEOUT } from '../../constants/app-common.constants';
import { LpToast } from '../toast/LpToast';
import { WuButton } from '@npm-questionpro/wick-ui-lib';

interface Props {
  reportUrl?: string;
}

export const CopyReportUrl = ({ reportUrl }: Props) => {
  const [showToast, setShowToast] = useState<boolean>();

  useEffect(() => {
    let timeoutId = null;
    if (showToast) {
      timeoutId = setTimeout(() => {
        setShowToast(false);
      }, SHOW_TOAST_TIMEOUT);
    }

    return () => {
      timeoutId && clearTimeout(timeoutId);
    };
  }, [showToast]);

  const handleCopyShareIconLink = () => {
    if (!reportUrl) {
      return;
    }
    if (navigator.clipboard && window.isSecureContext) {
      setShowToast(true);
      navigator.clipboard.writeText(reportUrl);
    }
  };

  if (!reportUrl) {
    return <></>;
  }

  return (
    <>
      {showToast && (
        <LpToast
          text="Report link copied to clipboard"
          onClose={() => setShowToast(false)}
        />
      )}
      {reportUrl && (
        <WuButton
          variant="secondary"
          onClick={handleCopyShareIconLink}
          icon={
            <span data-testid="share-icon">
              <CopyLinkSvg />
            </span>
          }
          className={styles.shareIconContainer}
        >
          <span className={styles.copyLinkText}>Copy report link</span>
        </WuButton>
      )}
    </>
  );
};
