import React from 'react';
import Highcharts from 'highcharts';

interface Props {
  width: number;
  height: number;
  color: string;
  filledPercentage: number;
}

export const LpPieChart = ({
  width,
  height,
  color,
  filledPercentage,
}: Props) => {
  const ref = React.useRef<HTMLDivElement | null>(null);

  const [isInitialDataSet] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!ref.current || isInitialDataSet) {
      return;
    }

    Highcharts.chart(
      ref.current,
      {
        credits: {
          enabled: false,
        },
        chart: {
          height: height + 4,
          width: width + 4,
          type: 'pie',
          spacing: [0, 0, 0, 0],
          margin: [0, 0, 0, 0],
          animation: false, // Disable animation
        },
        title: {
          text: undefined, // No title for a small chart
        },
        tooltip: {
          enabled: false, // Disable tooltips
        },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: false, // Disable data labels
            },
            enableMouseTracking: false, // Disable hover, click, and mouse events
            size: width,
            borderWidth: 0,
          },
        },
        series: [
          {
            type: 'pie',
            data: [
              { name: '', y: filledPercentage, color },
              { name: '', y: 100 - filledPercentage, color: '#eee' },
            ],
          },
        ],
      },
      () => {},
    );
  }, [ref, width, height, color, isInitialDataSet, filledPercentage]);

  return (
    <div>
      <div ref={ref} data-testid="questionResultLiveChart" />
    </div>
  );
};
