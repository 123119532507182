import { ILivePollSession } from '../../interfaces/livepoll-session.interface';
import { secondsToHms } from '../../utils/DateTimeUtil';
import styles from './LpResponseCountAndTime.module.css';
import { ResponseCountSvg } from '../svgImages/ResponseCount.svg';
import { TimerSvg } from '../svgImages/Timer.svg';
import { BackButtonSvg } from '../svgImages/BackButton.svg';
import { CopyReportUrl } from '../copy-report-url/CopyReportUrl';
import { WuButton } from '@npm-questionpro/wick-ui-lib';

interface Props {
  livePollSession: ILivePollSession;
  responseCount: number;
  onBackButtonClick?(): void;
  reportUrl?: string;
}

export const LpResponseCountAndTime = (props: Props) => {
  const { livePollSession, responseCount, onBackButtonClick, reportUrl } =
    props;

  const timeTaken = calculateTimeTaken(livePollSession);
  const sessionName = livePollSession.name || 'Untitled';

  const backButtonClick = () => {
    onBackButtonClick && onBackButtonClick();
  };

  return (
    <div className={styles.headerContainer}>
      <div className={styles.sessionName}>
        {onBackButtonClick && (
          <WuButton
            variant="iconOnly"
            icon={
              <span data-testid="backIcon">
                <BackButtonSvg />
              </span>
            }
            onClick={backButtonClick}
            className={styles.backButton}
          />
        )}

        {sessionName}
      </div>
      <div className={styles.responseCountContainer}>
        <span className={styles.responseCountIcon}>
          <ResponseCountSvg width={16} height={16} />
        </span>
        <span className={styles.responseCount} data-testid="response-count">
          {responseCount}
        </span>
      </div>
      {timeTaken && (
        <div className={styles.sessionTimeContainer}>
          <span className={styles.timerSvg}>
            <TimerSvg width={16} height={16} />
          </span>
          <span className={styles.sessionTime} data-testid="time taken">
            {timeTaken}
          </span>
          <span className={styles.sessionTimePostfix}>min</span>
        </div>
      )}
      <CopyReportUrl reportUrl={reportUrl} />
    </div>
  );
};

function calculateTimeTaken(session: ILivePollSession): string | null {
  if (!session.completedAt) {
    return null;
  }

  const startTime = new Date(session.createdAt).getTime();
  const endTime = new Date(session.completedAt).getTime();
  const diffInSeconds = (endTime - startTime) / 1000;

  return secondsToHms(diffInSeconds);
}
