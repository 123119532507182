import styles from './LpMultipleChoiceQuestionLeaderboard.module.css';
import { IMultipleChoiceQuestionReport } from '../../../interfaces/multiple-choice-question-report';
import { RibbonSvg } from '../../svgImages/Ribbon.svg';
import { WuTooltip } from '@npm-questionpro/wick-ui-lib';

interface Props {
  responseCount: number;
  questionReport: IMultipleChoiceQuestionReport;
}

export const LpMultipleChoiceQuestionLeaderboard = ({
  questionReport,
}: Props) => {
  const { leaderboard } = questionReport;

  if (leaderboard && leaderboard.length > 0) {
    const leaderboardRows = leaderboard.map((r: any, i) => (
      <div
        key={i}
        className={i === 0 ? styles.questionLeaderboardFirst : ''}
        data-testid="topRespondentsForQuestion"
      >
        {i === 0 && (
          <span className={styles.ribbonImage}>
            <RibbonSvg />
          </span>
        )}
        <div>
          <div className={styles.questionLeaderboardName}>
            <WuTooltip content={getHoverText(i)} position="bottom">
              {r.respondentName}
            </WuTooltip>
          </div>
          <div className={styles.questionLeaderboardScore}>{r.score}</div>
        </div>
      </div>
    ));

    return (
      <div
        className={styles.questionLeaderboard}
        data-testid="responseRateForQuestion"
      >
        {leaderboardRows}
      </div>
    );
  }
  return null;
};

function getHoverText(index: number) {
  const rank = index + 1;
  return `Rank ${rank}`;
}
