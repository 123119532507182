import { ChartView } from '../../../../enums/chart-view.enum';
import clockSvg from '../../../../images/clock.svg';
import whiteClockSvg from '../../../../images/white-clock.svg';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './LpSwipeQuestionToggleForQuiz.module.css';
import { WuTooltip } from '@npm-questionpro/wick-ui-lib';

interface Props {
  onChangeView: (currentView: ChartView) => void;
  currentView: ChartView;
}

export const LpSwipeQuestionToggleForQuiz = ({
  onChangeView,
  currentView,
}: Props) => {
  return (
    <div className={styles.toggle}>
      <div
        className={
          currentView === ChartView.RIGHT_SWIPES_RESPONSE_RATE
            ? styles.activeViewForResponse
            : styles.activeViewForCard
        }
      >
        <WuTooltip
          content={
            currentView === ChartView.RIGHT_SWIPES_RESPONSE_RATE
              ? 'Sort by accuracy'
              : 'Sort by card order'
          }
          position="bottom"
        >
          <div className={styles.row}>
            {currentView === ChartView.RIGHT_SWIPES_RESPONSE_RATE ? (
              <FontAwesomeIcon
                icon={faCheck}
                height={16}
                width={16}
                color="#fff"
                data-testid="highest correct percentage active"
              />
            ) : (
              <img
                src={whiteClockSvg}
                height={16}
                width={16}
                alt="chronological view active"
              />
            )}
          </div>
        </WuTooltip>
      </div>

      <WuTooltip content="Sort by accuracy" position="bottom">
        <FontAwesomeIcon
          icon={faCheck}
          height={16}
          width={16}
          className={styles.icon}
          onClick={() => onChangeView(ChartView.RIGHT_SWIPES_RESPONSE_RATE)}
          data-testid="disable highest correct percentage view"
        />
      </WuTooltip>
      <WuTooltip content="Sort by card order" position="bottom">
        <img
          height={16}
          width={16}
          src={clockSvg}
          onClick={() => onChangeView(ChartView.CHRONOLOGICAL)}
          alt="disable chronological view"
          className={styles.icon}
        />
      </WuTooltip>
    </div>
  );
};
