import styles from './LpSwipeQuestionToggleForTeams.module.css';
import userGroupSvg from '../../../../images/user-group.svg';
import userGroupWhiteSvg from '../../../../images/user-group-white.svg';
import fourDotsSvg from '../../../../images/four-dots-black.svg';
import fourDotsWhiteSvg from '../../../../images/four-dots-white.svg';
import { SwipeQuestionChartView } from '../../../../enums/teams-chart-view.enum';
import { WuTooltip } from '@npm-questionpro/wick-ui-lib';

interface Props {
  onChangeView: (currentView: SwipeQuestionChartView) => void;
  currentView: SwipeQuestionChartView;
}

export const LpSwipeQuestionToggleForTeams = ({
  onChangeView,
  currentView,
}: Props) => {
  return (
    <div className={styles.toggle}>
      <div
        className={
          currentView === SwipeQuestionChartView.INDIVIDUAL
            ? styles.activeViewForResponse
            : styles.activeViewForCard
        }
      >
        <WuTooltip
          content={
            currentView === SwipeQuestionChartView.INDIVIDUAL
              ? 'Individual view'
              : 'Team view'
          }
          position="bottom"
        >
          {currentView === SwipeQuestionChartView.INDIVIDUAL ? (
            <img src={fourDotsWhiteSvg} alt="individual view active" className={styles.activeIcon}/>
          ) : (
            <img src={userGroupWhiteSvg} alt="teams view active" className={styles.activeIcon}/>
          )}
        </WuTooltip>
      </div>
      <WuTooltip content="Individual view" position="bottom">
        <img
          height={16}
          width={16}
          src={fourDotsSvg}
          onClick={() => onChangeView(SwipeQuestionChartView.INDIVIDUAL)}
          alt="disable individual view"
          className={styles.icon}
        />
      </WuTooltip>

      <WuTooltip content="Team view" position="bottom">
        <img
          height={16}
          width={16}
          src={userGroupSvg}
          onClick={() => onChangeView(SwipeQuestionChartView.TEAMS)}
          alt="disable teams view"
          className={styles.icon}
        />
      </WuTooltip>
    </div>
  );
};
